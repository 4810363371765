<template>
  <form>
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      color="dark"
      :closeOnBackdrop="false"
      :title="$t('label.columnConfig')"
      :show.sync="modalActive"
      size="xl"
    >   
        <div>
            <CRow class="m-1" v-if="ModalList?.filter != undefined">
                <CCol sm="4" lg="4" xl="4">
                    <div class="form-group row">
                        <b class="col-sm-6 col-lg-8 col-xl-8 text-right"><label>{{ `${this.$t('label.filtro')}`}}</label></b>
                    </div>
                </CCol>
                <CCol sm="8" lg="8" xl="8"></CCol>
                <CCol sm="4" lg="4" xl="4">
                    <CSelect
                        :horizontal="{ label: 'col-sm-6 col-lg-8 text-right', input: 'col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center'}"
                        :label="`${this.$t('label.measureUnit')}`" 
                        :options="UnitMeasureWeigthOptions"
                        v-model="ModalList.weightSelected"
                    ></CSelect>
                </CCol>
            </CRow>
            <CRow class="m-1">
                <CCol sm="4" lg="4" xl="4">
                    <div class="form-group row">
                        <b class="col-sm-6 col-lg-8 col-xl-8 text-right"><label>{{ `${this.$t('label.columns')}`}}</label></b>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"></CCol>
                <CCol sm="4" lg="4" xl="4">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.selectAll')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center ">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.selectAll"
                                @update:checked=" selectAll(ModalList, $event)"
                            />
                        </div>
                    </div>
                </CCol>
            </CRow>
            <CRow class="m-1">
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.booking != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.booking')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.booking"
                                @update:checked="ModalList.booking = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.itinerary != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.itinerary')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.itinerary"
                                @update:checked="ModalList.itinerary = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.eta != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">ETA</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center ">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.eta"
                                @update:checked="ModalList.eta = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.number != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.UnNumber')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center ">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.number"
                                @update:checked="ModalList.number = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>                
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.eirReception != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.receptionEirNo')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.eirReception"
                                @update:checked="ModalList.eirReception = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.broker != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.customBroker')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.broker"
                                @update:checked="ModalList.broker = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>

                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.typeOperation != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.customsRegime')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center ">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.typeOperation"
                                @update:checked="ModalList.typeOperation = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.originVisit != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.originVisit')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.originVisit"
                                @update:checked="ModalList.originVisit = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.ata != undefined">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">ATA</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.ata"
                                @update:checked="ModalList.ata = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol> 

                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.countryDay != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.DaysInCountry')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.countryDay"
                                @update:checked="ModalList.countryDay = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.dea != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">DEA</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dea"
                                @update:checked="ModalList.dea = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>  
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.machine != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.machine')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.machine"
                                @update:checked="ModalList.machine = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>     
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.carrier != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.transport')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.carrier"
                                @update:checked="ModalList.carrier = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.driver != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.driver')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.driver"
                                @update:checked="ModalList.driver = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol> 
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.vehicle != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.vehicle')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.vehicle"
                                @update:checked="ModalList.vehicle = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.motiveCancel != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.CancellationReason')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.motiveCancel"
                                @update:checked="ModalList.motiveCancel = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol> 
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.dateCancel != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.cancelationDate')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dateCancel"
                                @update:checked="ModalList.dateCancel = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.forAutorized != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.AUTHORIZED_BY')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.forAutorized"
                                @update:checked="ModalList.forAutorized = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.visit != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.visit')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.visit"
                                @update:checked="ModalList.visit = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.atd != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.atd')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.atd"
                                @update:checked="ModalList.atd = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.positionPlanifiqued != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.plannedPosition')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.positionPlanifiqued"
                                @update:checked="ModalList.positionPlanifiqued = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4" v-if="ModalList?.positionExit != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.DEPARTURE_POSITION')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.positionExit"
                                @update:checked="ModalList.positionExit = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.codeIso != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.isoCode')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.codeIso"
                                @update:checked="ModalList.codeIso = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.condition != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.condition')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.condition"
                                @update:checked="ModalList.condition = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.seal != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.Seals')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.seal"
                                @update:checked="ModalList.seal = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.diceContener != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.inventoryContains')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.diceContener"
                                @update:checked="ModalList.diceContener = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.imdg != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.imdg')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.imdg"
                                @update:checked="ModalList.imdg = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.cant != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.quantity')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.cant"
                                @update:checked="ModalList.cant = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.weight != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.weight')} (TON)`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.weight"
                                @update:checked="ModalList.weight = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.volume != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.volume')} (M³)`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.volume"
                                @update:checked="ModalList.volume = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.temperature != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.temperature')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.temperature"
                                @update:checked="ModalList.temperature = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.conected != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.connected')}?`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.conected"
                                @update:checked="ModalList.conected = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.ReeferPlug != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.Reefer_Plug')}?`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.ReeferPlug"
                                @update:checked="ModalList.ReeferPlug = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.dinTop != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.DimTop')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dinTop"
                                @update:checked="ModalList.dinTop = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.dinLeft != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.DimLeft')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dinLeft"
                                @update:checked="ModalList.dinLeft = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.dinRight != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.DimRight')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dinRight"
                                @update:checked="ModalList.dinRight = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.dinFront != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.DimFront')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dinFront"
                                @update:checked="ModalList.dinFront = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.dinBack != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.DimBack')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dinBack"
                                @update:checked="ModalList.dinBack = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.packaging != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.packaging')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.packaging"
                                @update:checked="ModalList.packaging = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.rubro != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.heading')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.rubro"
                                @update:checked="ModalList.rubro = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.governmentPrivate != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.govermentPrivate')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.governmentPrivate"
                                @update:checked="ModalList.governmentPrivate = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.origin != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.Procedence')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.origin"
                                @update:checked="ModalList.origin = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.shippingLine != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.newShippingLine')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.shippingLine"
                                @update:checked="ModalList.shippingLine = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.dateReception != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.receptionDate')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dateReception"
                                @update:checked="ModalList.dateReception = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.dayYard != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.daysInYard')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dayYard"
                                @update:checked="ModalList.dayYard = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.lastOperation != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.endOperations')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.lastOperation"
                                @update:checked="ModalList.lastOperation = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.dateLastOperation != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.dateLastOperation')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dateLastOperation"
                                @update:checked="ModalList.dateLastOperation = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.passNumber != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.nticket')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.passNumber"
                                @update:checked="ModalList.passNumber = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.eirNumber != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.nEir')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.eirNumber"
                                @update:checked="ModalList.eirNumber = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.dua != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">DUA</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dua"
                                @update:checked="ModalList.dua = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.eir != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">EIR</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.eir"
                                @update:checked="ModalList.eir = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.receptionBp != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.bolipuertoReceptionRecordNro')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.receptionBp"
                                @update:checked="ModalList.receptionBp = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.customsRegime != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.customsRegime')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.customsRegime"
                                @update:checked="ModalList.customsRegime = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.sidunea != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">SIDUNEA</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.sidunea"
                                @update:checked="ModalList.sidunea = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.originPosicion != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.originPosition')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.originPosicion"
                                @update:checked="ModalList.originPosicion = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.detiny != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.destination')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.detiny"
                                @update:checked="ModalList.detiny = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.dateDispatch != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.dispatchDate')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dateDispatch"
                                @update:checked="ModalList.dateDispatch = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.user != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.USUARIO')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.user"
                                @update:checked="ModalList.user = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.device != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.device')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.device"
                                @update:checked="ModalList.device = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.dateTransaction != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.transactionDate')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dateTransaction"
                                @update:checked="ModalList.dateTransaction = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.observation != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.observation')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.observation"
                                @update:checked="ModalList.observation = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.asignedPosition != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.assignedPosition')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.asignedPosition"
                                @update:checked="ModalList.asignedPosition = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.damage != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.damageOption')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.damage"
                                @update:checked="ModalList.damage = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.status != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.status')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.status"
                                @update:checked="ModalList.status = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.actualStatus != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.actualStatus')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.actualStatus"
                                @update:checked="ModalList.actualStatus = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.dateEmbarkedConfirmed != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.shipmentConfirmationDate')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.dateEmbarkedConfirmed"
                                @update:checked="ModalList.dateEmbarkedConfirmed = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.event != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.event')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.event"
                                @update:checked="ModalList.event = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.startDate != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.startDate')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.startDate"
                                @update:checked="ModalList.startDate = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.inspectionDate != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.inspectionDate')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.inspectionDate"
                                @update:checked="ModalList.inspectionDate = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.endDate != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.endDate')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.endDate"
                                @update:checked="ModalList.endDate = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.totalTime != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.totalTime')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.totalTime"
                                @update:checked="ModalList.totalTime = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.currentPosicion != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.CurrentPosition')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.currentPosicion"
                                @update:checked="ModalList.currentPosicion = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.area != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.Area')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.area"
                                @update:checked="ModalList.area = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.previousPosicion != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.previousPosition')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.previousPosicion"
                                @update:checked="ModalList.previousPosicion = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.newPosition != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.newPosition')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.newPosition"
                                @update:checked="ModalList.newPosition = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="4" lg="4" xl="4"  v-if="ModalList?.registerDate != undefined">
                    <div class="form-group row">
                        <label class="col-sm-6 col-lg-8 col-xl-8 text-right">{{ `${this.$t('label.registerDate')}`}}</label>
                        <div class="col-sm-6 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="ModalList.registerDate"
                                @update:checked="ModalList.registerDate = $event, ModalList.selectAll = false"
                            />
                        </div>
                    </div>
                </CCol>
            </CRow>
        </div>
      <div slot="footer">
          <CButton outline color="wipe" @click="closeModal()">
              <CIcon name="x"/>&nbsp;
              {{$t('button.exit')}}
          </CButton>
      </div>
  </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import { required, maxLength, helpers } from 'vuelidate/lib/validators';
import UpperCase from '@/_validations/uppercase-directive';
import { DateFormater } from '@/_helpers/funciones';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    TpEirName: '',
    apiStateLoading: false,
    FeaturesDescription:'',
    rubros: [],
    imdg: [],
    isLoadingMulti: false,
    tabIndex: 0,
    ValidPreviousDate: new Date(),
    ValidLaterDate: new Date(),
    previousDate: new Date(),
    laterDate: new Date(),
    IssueDate: '', 
    FinishDate: '',
    TransactionDate: '',
  
  };
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function selectAll(ModalList, $event) {
    if(ModalList?.booking != undefined)  ModalList.booking = $event
    if(ModalList?.itinerary != undefined)  ModalList.itinerary = $event
    if(ModalList?.eta != undefined)  ModalList.eta = $event
    if(ModalList?.number != undefined)  ModalList.number = $event
    if(ModalList?.eirReception != undefined)  ModalList.eirReception = $event
    if(ModalList?.broker != undefined)  ModalList.broker = $event
    if(ModalList?.typeOperation != undefined)  ModalList.typeOperation = $event
    if(ModalList?.originVisit != undefined)  ModalList.originVisit = $event
    if(ModalList?.ata != undefined)  ModalList.ata = $event
    if(ModalList?.countryDay != undefined)  ModalList.countryDay = $event
    if(ModalList?.dea != undefined)  ModalList.dea = $event
    if(ModalList?.machine != undefined)  ModalList.machine = $event
    if(ModalList?.carrier != undefined)  ModalList.carrier = $event
    if(ModalList?.driver != undefined)  ModalList.driver = $event
    if(ModalList?.vehicle != undefined)  ModalList.vehicle = $event
    if(ModalList?.motiveCancel != undefined)  ModalList.motiveCancel = $event
    if(ModalList?.dateCancel != undefined)  ModalList.dateCancel = $event
    if(ModalList?.forAutorized != undefined)  ModalList.forAutorized = $event
    if(ModalList?.visit != undefined)  ModalList.visit = $event
    if(ModalList?.atd != undefined)  ModalList.atd = $event
    if(ModalList?.positionPlanifiqued != undefined)  ModalList.positionPlanifiqued = $event
    if(ModalList?.positionExit != undefined)  ModalList.positionExit = $event
    if(ModalList?.codeIso != undefined)  ModalList.codeIso = $event
    if(ModalList?.condition != undefined)  ModalList.condition = $event
    if(ModalList?.seal != undefined)  ModalList.seal = $event
    if(ModalList?.diceContener != undefined)  ModalList.diceContener = $event
    if(ModalList?.imdg != undefined)  ModalList.imdg = $event
    if(ModalList?.cant != undefined)  ModalList.cant = $event
    if(ModalList?.weight != undefined)  ModalList.weight = $event
    if(ModalList?.volume != undefined)  ModalList.volume = $event
    if(ModalList?.temperature != undefined)  ModalList.temperature = $event
    if(ModalList?.conected != undefined)  ModalList.conected = $event
    if(ModalList?.dinTop != undefined)  ModalList.dinTop = $event
    if(ModalList?.dinLeft != undefined)  ModalList.dinLeft = $event
    if(ModalList?.dinRight != undefined)  ModalList.dinRight = $event
    if(ModalList?.dinFront != undefined)  ModalList.dinFront = $event
    if(ModalList?.dinBack != undefined)  ModalList.dinBack = $event
    if(ModalList?.packaging != undefined)  ModalList.packaging = $event
    if(ModalList?.rubro != undefined)  ModalList.rubro = $event
    if(ModalList?.governmentPrivate != undefined)  ModalList.governmentPrivate = $event
    if(ModalList?.origin != undefined)  ModalList.origin = $event
    if(ModalList?.shippingLine != undefined)  ModalList.shippingLine = $event
    if(ModalList?.dateReception != undefined)  ModalList.dateReception = $event
    if(ModalList?.dayYard != undefined)  ModalList.dayYard = $event
    if(ModalList?.lastOperation != undefined)  ModalList.lastOperation = $event
    if(ModalList?.dateLastOperation != undefined)  ModalList.dateLastOperation = $event
    if(ModalList?.passNumber != undefined)  ModalList.passNumber = $event
    if(ModalList?.eirNumber != undefined)  ModalList.eirNumber = $event
    if(ModalList?.dua != undefined)  ModalList.dua = $event
    if(ModalList?.customsRegime != undefined)  ModalList.customsRegime = $event
    if(ModalList?.receptionBp != undefined)  ModalList.receptionBp = $event
    if(ModalList?.actualStatus != undefined)  ModalList.actualStatus = $event
    if(ModalList?.sidunea != undefined)  ModalList.sidunea = $event
    if(ModalList?.originPosicion != undefined)  ModalList.originPosicion = $event
    if(ModalList?.detiny != undefined)  ModalList.detiny = $event
    if(ModalList?.dateDispatch != undefined)  ModalList.dateDispatch = $event
    if(ModalList?.user != undefined)  ModalList.user = $event
    if(ModalList?.device != undefined)  ModalList.device = $event
    if(ModalList?.dateTransaction != undefined)  ModalList.dateTransaction = $event
    if(ModalList?.observation != undefined)  ModalList.observation = $event
    if(ModalList?.asignedPosition != undefined)  ModalList.asignedPosition = $event
    if(ModalList?.damage != undefined)  ModalList.damage = $event
    if(ModalList?.status != undefined)  ModalList.status = $event
    if(ModalList?.ReeferPlug != undefined)  ModalList.ReeferPlug = $event
    if(ModalList?.dateEmbarkedConfirmed != undefined)  ModalList.dateEmbarkedConfirmed = $event
    if(ModalList?.event != undefined)  ModalList.event = $event
    if(ModalList?.startDate != undefined)  ModalList.startDate = $event
    if(ModalList?.inspectionDate != undefined)  ModalList.inspectionDate = $event
    if(ModalList?.endDate != undefined)  ModalList.endDate = $event
    if(ModalList?.totalTime != undefined)  ModalList.totalTime = $event
    if(ModalList?.currentPosicion != undefined)  ModalList.currentPosicion = $event
    if(ModalList?.area != undefined)  ModalList.area = $event
    if(ModalList?.previousPosicion != undefined)  ModalList.previousPosicion = $event
    if(ModalList?.newPosition != undefined)  ModalList.newPosition = $event
    if(ModalList?.registerDate != undefined)  ModalList.registerDate = $event
    if(ModalList?.registerDate != undefined) ModalList.eir = $event
}

function closeModal() {
  this.modalActive = false;
}

function UnitMeasureWeigthOptions() {
  if(this.ModalList.UnitMeasureWeigth && (this.ModalList.UnitMeasureWeigth?.length != 0)){
    let chart = []
    this.ModalList.UnitMeasureWeigth?.map(function(e){
      chart.push({
        value: e.value,
        label: e.label,
      })
    })
    return chart;
  }
}

export default {
  name: 'modal-columns',
  components: {
   },
  props: { modal: Boolean, ModalList: Object },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin
  ],
  validations: {
    TpEirName: { required, maxLength: maxLength(30) },
  },
  directives: UpperCase,
  methods: {
    validateDateRange,
    closeModal,
    selectAll
  },
  computed: {
    UnitMeasureWeigthOptions
  },
  watch: {
    modal: function(val){
      this.modalActive = val;
      /*if (this.isEdit==true) {
        this.getdata(this.IncidenceGpoItem);
      }*/
    },
    TransactionDate: async function(val){
      try{
        if(val != ''){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.ErrorAtaPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.ErrorEtaPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorAtdPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.IssueDate) {
              throw this.$t('label.ErrorOrderDateIssuePleaseCheck');
          }
          if (this.FinishDate != this.ValidLaterDate && DateFormater.formatDateTimeWithoutSlash(val) > this.FinishDate) {
              throw this.$t('label.ErrorOrderDateFinishPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
  },
 
};
</script>