<template>
  <div class="px-2">
    <CRow class="mb-3">
      <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ `${$t('label.dispatchList')}`}}</h6>
      </CCol>
      <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
          <CButton
              color="excel"
              class="mr-1" 
              size="sm"
              @click="onBtnExport(true)"
          >
              <CIcon name="cil-file-excel"/>&nbsp; XSLX
          </CButton>
          <CButton
              color="watch"
              size="sm"  
              @click="onBtnExport(false)"
          >
              <CIcon name="cil-file-excel"/>&nbsp; CSV
          </CButton>
      </CCol>
    </CRow>
    <CRow>
        <CCol sm="12" lg="4">
            <div class="form-group form-row" rol="group">
                <label class="col-form-label col-sm-12 col-lg-2 text-right">{{$t('label.visit')}}</label>
                <div class="col-sm-12 col-lg-9 input-group">
                    <v-select
                        class="select-adjust"
                        :placeholder="$t('label.select')"
                        v-model="Visit"
                        :options="VisitOptions"
                        :getOptionLabel="option => option.label"
                        :reduce="option => option.Json" 
                    >
                        <template #no-options="{ }">
                            {{$t('label.noResultsFound')}}
                        </template>
                        <template #option="{ Json, label, value }">
                            {{ label }}<br />
                            <cite v-if="value">
                                {{ Json.VoyageArrival }} - {{ 'ATA: '+Json.CumputedAta }} 
                            </cite>
                        </template>
                    </v-select>
                </div>
            </div>
        </CCol>
        <CCol sm="12" lg="7">
            <div class="form-group form-row d-flex aling-items-center mb-0">
                <label class="col-form-label col-sm-12 col-lg-1 text-right mb-2">{{`${$t('label.date')}`}}</label>
                <div class="input-group col-sm-12 col-lg-4 mb-2">
                    <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="searchFilterDate.fromDay"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                    >
                        <template #icon-calendar>
                            <div style="display: none"></div>
                        </template>
                    </vue-datepicker>
                </div>
                <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
                <div class="input-group col-sm-12 col-lg-4 mb-2">
                    <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="true"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="searchFilterDate.toDay"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                    >
                        <template #icon-calendar>
                            <div style="display: none"></div>
                        </template>
                    </vue-datepicker>
                </div>
                <div class="d-flex align-items-start justify-content-end col-sm-12 col-lg-auto">
                    <CButton
                        color="watch"
                        square
                        size="sm"
                        v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                        class="mx-1"
                        @click="getYardDispatchImportList()"
                    >
                        <CIcon name="cil-search"/>
                    </CButton>
                    <CButton
                        color="danger"
                        square
                        size="sm"
                        v-c-tooltip="{ content: $t('label.clearFilters'), placement: 'top-end'}"
                        @click="refreshComponent()"
                    >
                        <CIcon name="cil-brush-alt"/>
                    </CButton>
                </div>
            </div>
        </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" lg="3">
          <CInput
            :label="$t('label.filter')"
            :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9'}"
            :placeholder="$t('label.enterSearch')" 
            @keyup="onQuickFilterChanged($event.target.value)"         
          >
          </CInput>  
      </CCol>
      <CCol sm="12" lg="5"></CCol>
      <CCol sm="12" lg="4" class="justify-content-end">
          <CSelect
            :horizontal="{ label: 'col-sm-12 col-lg-8 text-right', input: 'col-sm-12 col-lg-4'}"
            :label="$t('label.recordsPerPage')"
            @change="onPageSizeChanged"
            :options="[50,100,500,1000]"
          >
          </CSelect>
      </CCol>
  </CRow>
  <CRow>
    <CCol sm="12" lg="12" xl="12" class="mb-2">
        <div class="d-flex bd-highlight">
            <div class="bd-highlight" style="width:50px">
                <CButton
                    block
                    style="height: 100vh;"
                    shape="square"
                    color="light"
                    @click="ModalColumn = true"
                >
                    <div id="vertical-orientation" class="d-flex align-items-center border-right justify-content-center">
                        <CIcon name="cil-cog" class="correct-vertical"/>
                    </div>
                </CButton>
            </div>
            <div class="flex-grow-1 bd-highlight">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :getRowStyle="getRowStyle"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="RowData"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </div>
        </div>
    </CCol>
  </CRow>
  <ModalRegister
    :modal.sync="ModalColumn"
    :ModalList="columnsOption"
  />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import AgGrid from '@/_mixins/ag-grid';
import YardManagement from '@/_mixins/yardManagement';
import { localeKey } from '@/_helpers/locale.grid';
import { DateFormater, NumberFormater, trimRemoveSpecialCharacters } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import moment from 'moment';
import ModalRegister from './modal-columns';

//DATA-------------------------
function data() {
  return {
    gridOptions: null,
    defaultColDef: null,
    paginationPageSize: null,
    paginationNumberFormatter: null,
    gridApi: null,
    columnApi: null,
    Visit: '',
    VisitName: '',
    VisitList: [],
    formatedItems: [],
    searchFilterDate: {
        fromDay: '',
        toDay: '',
    },
    DateRange: '',
    previousDate: '',
    laterDate: '',
    ModalColumn:false,
    columnsOption:{
      filter:true,
      selectAll:true,
      seal:true,
      dateReception:true,
      number:true,
      broker:true,
      machine: true,
      carrier:true,
      driver: true,
      vehicle: true,
      codeIso:true,
      condition:true,
      diceContener:true,
      imdg:true,
      weight:true,
      volume:true,
      temperature:true,
      conected:true,
      dinTop:true,
      dinLeft:true,
      dinRight:true,
      dinFront:true,
      dinBack:true,
      rubro:true,
      governmentPrivate:true,
      origin:true,
      shippingLine:true,
      dayYard:true,
      passNumber:true,
      eirNumber:true,
      dua:true,
      originPosicion:true,
      detiny:true,
      dateDispatch:true,
      user:true,
      device:true,
      dateTransaction:true,
      observation:true,
      customsRegime:true,
      receptionBp:true,
      weightSelected: 1,
      UnitMeasureWeigth:[
        {
            value: 1,
            label: 'TON'
        },
        {
            value: 2,
            label: 'KGM'
        }
      ],
    },
  }
}

//beforeMount
function beforeMount() {
    this.gridOptions = {};
    this.defaultColDef = {
        editable: false,
        resizable: true,
        sortable: true,
        minWidth: 150,
        flex: 1,
    };
    this.paginationPageSize = 50;
    this.paginationNumberFormatter = (params) => {
        return params.value.toLocaleString();
    };
}

//methods
async function getYardVisit() {
    await this.$http.get('YardVisit-list', { CompanyBranchId: this.CompanyBranchId })
        .then(response => {
            this.VisitList = response.data.data ?? [];
        })
        .catch( err => {
            this.$notify({
                group: 'yard',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
}

function getYardDispatchImportList() {
    this.$store.state.yardManagement.loading = true;
    
    let dateStart = '';
    let dateEnd = '';

    dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
    dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);        

    let ReportJson = {
        YardId:this.yardData?.YardId,//Id del Patio 
        VisitId: this.Visit?.VisitId ?? '',//Id de la Visita seleccionada
        DateStart: dateStart,//Fecha desde 
        DateEnd: dateEnd,//Fecha hasta (Fecha Actual valor maximo)
    };

    this.$http.post('YardDispatchImport-list', ReportJson, { root: 'ReportJson'})
        .then(response => {
            this.VisitName = this.Visit?.VisitId ? 
                this.$t('label.visit')+': '+this.Visit?.VesselName+' - '+this.Visit?.VoyageArrival+ ' - ATA: '+this.Visit?.Ata :
                this.$t('label.visit')+': N/A';
            this.DateRange = this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay;
            let List = response.data.data ?? [];
            this.formatedItems = Object.entries(List[0]).length > 1 ? List : [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.$store.state.yardManagement.loading = false;
        });
}
function onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
}

function onQuickFilterChanged(value) {
    this.gridApi.setQuickFilter(value);
}

function onPageSizeChanged(event) {
    this.gridApi.paginationSetPageSize(parseInt(event.target.value));
}

function getDateRange(){
    this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
    this.laterDate = new Date();
    this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(moment(this.laterDate).subtract(1, 'M'));
    this.searchFilterDate.toDay =  DateFormater.formatTimeZoneToDateTime(this.laterDate);
}

async function refreshComponent(Filter) {
    this.$store.state.yardManagement.loading = true;
    this.formatedItems = [];
    this.Visit = '';
    this.VisitName = '';
    this.searchFilterDate = {
        fromDay: '',
        toDay: '',
    };
    this.DateRange = '';
    this.previousDate = '';
    this.laterDate = '';
    await this.getDateRange();
    if (Filter) {
        this.VisitList = [];
        await this.getYardVisit();
        this.columnsOption = {
            filter:true,
            selectAll:true,
            seal:true,
            dateReception:true,
            number:true,
            broker:true,
            machine: true,
            carrier:true,
            driver: true,
            vehicle: true,
            codeIso:true,
            condition:true,
            diceContener:true,
            imdg:true,
            weight:true,
            volume:true,
            temperature:true,
            conected:true,
            dinTop:true,
            dinLeft:true,
            dinRight:true,
            dinFront:true,
            dinBack:true,
            rubro:true,
            governmentPrivate:true,
            origin:true,
            shippingLine:true,
            dayYard:true,
            passNumber:true,
            eirNumber:true,
            dua:true,
            originPosicion:true,
            detiny:true,
            dateDispatch:true,
            user:true,
            device:true,
            dateTransaction:true,
            observation:true,
            customsRegime:true,
            receptionBp:true,
            weightSelected: 1,
            UnitMeasureWeigth:[
                {
                    value: 1,
                    label: 'TON'
                },
                {
                    value: 2,
                    label: 'KGM'
                }
            ],
        }
    }
    await this.getYardDispatchImportList();
}

async function onBtnExport(valor){
    if(this.formatedItems.length !== 0) {
        this.$store.state.yardManagement.loading = true;
        let currentDate = new Date();
        let rowData = [];
        let Filter = []; 
        let rowDataFormated = [];

        Filter[0] = this.DateRange;
        Filter[1] = this.VisitName;
        Filter[2] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        Filter[3] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        rowDataFormated = rowData.map(item => {
            return {
                ...item,
                Ata: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : '',
                FgPlugged: item.FgPlugged==0 ? this.$t('label.no') : this.$t('label.yes'),
                FgGovernment: item.FgGovernment==0 ? this.$t('label.no') : this.$t('label.yes'),
                ReceptionDate: item.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : '',
                DispatchDate: item.DispatchDate ? DateFormater.formatDateTimeWithSlash(item.DispatchDate) : '',
                TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
            }
        })
        await this.getReporExcel(rowDataFormated, `${this.$t('label.dispatchList')}`, valor, Filter);
        this.$store.state.yardManagement.loading = false;
    }else{
        this.$notify({  
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.noRecordsAvailbleReport'),
            type: "error" 
        });
    }
}

//computed
function RowData(){
    let _lang = this.$i18n.locale;
    return this.formatedItems.map(item => {

        let WeigthTon = 0, WeigthKmg = 0;

        item.WeigthJson.map((e)=>{
            if(e.UnitMeasureAbbrev === "TON")
                WeigthTon = e.Value;
            else
                WeigthKmg = e.Value;
        })

        return {
            ...item,
            DimTop: item.DimTop&&item.DimTop>0 ? NumberFormater.formatNumber(item.DimTop, 2) : '',
            DinLeft: item.DinLeft&&item.DinLeft>0 ? NumberFormater.formatNumber(item.DinLeft, 2) : '',
            DinFront: item.DinFront&&item.DinFront>0 ? NumberFormater.formatNumber(item.DinFront, 2) : '',
            DinBack: item.DinBack&&item.DinBack>0 ? NumberFormater.formatNumber(item.DinBack, 2) : '',
            DescriptionOfGoods: trimRemoveSpecialCharacters(item.DescriptionOfGoods),
            OriginPosition: item.OriginPositionJson.map(Position => Position.SlotCode.trim()).join(', '),
            DaysInYard: _lang=='en' ? item.DaysInYardEn||'' : item.DaysInYardEs||'',
            WeigthTon: WeigthTon,
            WeigthKmg: WeigthKmg
        }
    });
}

function localeText(){
    let AG_GRID_LOCALE = {};
    for (let index = 0; index < localeKey.length; index++) {
        const element = localeKey[index];
        AG_GRID_LOCALE[element] = this.$t('agridTable.'+element);
    }
    return AG_GRID_LOCALE;
}

function VisitOptions() {
    if(this.VisitList.length === 0){
        return [{
            label: this.$t('label.select'),
            value: '',
            Json: {
                VoyageArrival: '',
                Eta: '',
                VisitStatus: '',
            },
        }];
    }else{
        let chart = [{
            label: this.$t('label.select'),
            value: '',
            Json: {
                VoyageArrival: '',
                Eta: '',
                VisitStatus: '',
            }
        }];
        this.VisitList.map(function(e){
            chart.push({
                label: e.VesselName,
                value: e.VisitId,
                Json: {
                    ...e,
                    VoyageArrival: e.VoyageArrival,
                    CumputedAta: DateFormater.formatDateTimeWithSlash(e.Ata),
                }
            })    
        })
        return chart;
    };
}

function columnDefs(){
    let columnDefs = [
        {
            field: "Nro",
            headerName: '#',
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 100,
            cellClass: 'gb-cell',
            headerClass: 'center-cell-especial',
        },
        {
            field: "GpoCargoName",
            headerName: this.$t('label.cargoGroup'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
            cellClass: 'gb-cell',
            headerClass: 'center-cell-especial',
        },
        {
            field: "ContainerCode",
            headerName: `${this.$t('label.container')}/${this.$t('label.serial')}`,
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 220,
            cellClass: 'gb-cell',
            headerClass: 'center-cell-especial',
        },
        {
            field: "TpCargoDetailCode",
            headerName: this.$t('label.type'), 
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
        },
        {
            field: "TpTransacEirAcronym" ,
            headerName: this.$t('label.transaction'), 
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
        },
        {
            field: "BlNro",
            headerName: this.$t('label.bl'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
        },
        {
            field: "ConsigneeName",
            headerName: this.$t('label.consignee'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
        },
        {
            field: "VisitDs",
            headerName: this.$t('label.visit'), 
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
        },
        {
            field: "Ata",
            headerName: 'ATA',
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
            cellRenderer: params => {
                return params.value ? DateFormater.formatDateTimeWithSlash(params.value) : '';
            },
        },
        {
            field: "PortActivityAbbrev",
            headerName: this.$t('label.portActivity'), 
            filter: 'agTextColumnFilter',
            cellClass: ' center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 200,
        },
    ]
      
    if(this.columnsOption.codeIso){
        columnDefs.push({
            field: "IsoCode",
            headerName: this.$t('label.isoCode'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 160,
        });
    }

      if(this.columnsOption.condition){
        columnDefs.push({
            field: "TpCargoConditionCode",
            headerName: this.$t('label.condition'),
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 160,
        });
      }

    if(this.columnsOption.seal){
        columnDefs.push({
            field: "Seals",
            headerName: this.$t('label.Seals'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 160,
        });
    }

    if(this.columnsOption.diceContener){
        columnDefs.push({
            field: "DescriptionOfGoods",
            headerName: this.$t('label.inventoryContains'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.imdg){
        columnDefs.push({
            field: "ImdgCode",
            headerName: this.$t('label.imdg'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 160,
        });
    }

    if(this.columnsOption.number){
        columnDefs.push({
            field: "UnNumber",
            headerName: this.$t('label.UnNumber'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 220,
        });
    }

    if(this.columnsOption.weight){
        columnDefs.push({
            field:  this.columnsOption.weightSelected === 1 ? "WeigthTon" : "WeigthKmg",
            headerName: this.columnsOption.weightSelected === 1 ?  `${this.$t('label.weight')}(TON)` :  `${this.$t('label.weight')}(KGM)`,  
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
            cellRenderer: params => {
                let variable = 0.00;
                if(params.value !== null && params.value !== '' ){    
                    variable = NumberFormater.formatNumber(params.value, 2);
                }
                return variable;
            },
        });
    }

    if(this.columnsOption.volume){
        columnDefs.push({
            field: "VolumenM3",
            headerName: `${this.$t('label.volume')}(M³)`, 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 160,
        });
    }

    if(this.columnsOption.temperature){
        columnDefs.push({
            field: "Temperature",
            headerName: this.$t('label.temperature'),
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 160,
        });
    }

    if(this.columnsOption.conected){
        columnDefs.push({
            field: "FgPlugged",
            headerName: `${this.$t('label.connected')}?`,
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 160,
            cellRenderer: params => {
                let variable = '';
                if(params.value !== null && params.value !== '' ){
                    variable = params.value==0 ? this.$t('label.no')  : this.$t('label.yes') ;
                }
                return variable;
            },
        });
    }

    if(this.columnsOption.dinTop){
        columnDefs.push({
            field: "DimTop",
            headerName: this.$t('label.DimTop'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }


    if(this.columnsOption.dinLeft){
        columnDefs.push({
            field: "DimLeft",
            headerName: this.$t('label.DimLeft'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }
    if(this.columnsOption.dinRight){
        columnDefs.push({
            field: "DimRight",
            headerName: this.$t('label.DimRight'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.dinFront){
        columnDefs.push({
            field: "DimFront",
            headerName: this.$t('label.DimFront'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.dinBack){
        columnDefs.push({
            field: "DimBack",
            headerName: this.$t('label.DimBack'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.rubro){
        columnDefs.push({
            field: this.$i18n.locale=='en' ? 'HeadingNameEn' : 'HeadingNameEs',
            headerName: this.$t('label.Heading'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 220,
        });
    }

    if(this.columnsOption.governmentPrivate){
        columnDefs.push({
            field: "FgGovernment",
            headerName: this.$t('label.govermentPrivate'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 220,
            cellRenderer: params => {
                let variable = '';
                if(params.value !== null && params.value !== '' ){
                    variable = params.value==false ? this.$t('label.no')  : this.$t('label.yes');
                }
                return variable;
            },
        });
    }

    if(this.columnsOption.origin){
        columnDefs.push({
            field: "Procedence",
            headerName: this.$t('label.Procedence'),
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.shippingLine){
        columnDefs.push({
            field: "ShippingLineCode",
            headerName: this.$t('label.newShippingLine'),
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.carrier){
        columnDefs.push({
            field: "CarrierName",
            headerName: this.$t('label.transport'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 160,
        });
    }

    if(this.columnsOption.driver){
        columnDefs.push({
            field: "DriverName",
            headerName: this.$t('label.driver'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 160,
        });
    }

    if(this.columnsOption.vehicle){
        columnDefs.push({
            field: "LicensePlate",
            headerName: this.$t('label.vehicle'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 160,
        });
    }

    if(this.columnsOption.dateReception){
        columnDefs.push({
            field: "ReceptionDate",
            headerName: this.$t('label.receptionDate'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
            cellRenderer: params => {
                return DateFormater.formatDateTimeWithSlash(params.value);
            },
        });
    }

    if(this.columnsOption.dayYard){
        columnDefs.push({
            field: "DaysInYard",
            headerName: this.$t('label.daysInYard'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.passNumber){
        columnDefs.push({
            field: "OutPass",
            headerName: this.$t('label.nticket'),  
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.eirNumber){
        columnDefs.push({
            field: "EirNumber",
            headerName: this.$t('label.nEir'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.dua){
        columnDefs.push({
            field: "Dua",
            headerName: `N° ${this.$t('label.dua')}`, 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.receptionBp){
        columnDefs.push({
            field: "ReceptionBpAct",
            headerName: this.$t('label.bolipuertoReceptionRecordNro'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 300,
        });
    }

    if(this.columnsOption.customsRegime){
        columnDefs.push({
            field: this.$i18n.locale=='en' ? 'CustomOperationDsEn' : 'CustomOperationDsEs',
            headerName: this.$t('label.customsRegime'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.broker){
        columnDefs.push({
            field: "CustomBrokerName",
            headerName: this.$t('label.customBroker'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.originPosicion){
        columnDefs.push({
            field: "OriginPosition",
            headerName: this.$t('label.originPosition'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.machine){
        columnDefs.push({
            field: "MachineName",
            headerName: this.$t('label.machine'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.detiny){
        columnDefs.push({
            field: "Destiny",
            headerName: this.$t('label.destination'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.dateDispatch){
        columnDefs.push({
            field: "DispatchDate",
            headerName: this.$t('label.dispatchDate'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
            cellRenderer: params => {
                return params.value ? DateFormater.formatDateTimeWithSlash(params.value) : '';
            },
        });
    }

    if(this.columnsOption.device){
        columnDefs.push({
            field: "DeviceCode",
            headerName: this.$t('label.device'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.observation){
        columnDefs.push({
            field: "Observation",
            headerName: this.$t('label.observation'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.user){
        columnDefs.push({
            field: "TransaLogin",
            headerName: this.$t('label.user'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
    }

    if(this.columnsOption.dateTransaction){
        columnDefs.push({
            field: "TransaRegDate",
            headerName: this.$t('label.transactionDate'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
            cellRenderer: params => {
                return params.value ? DateFormater.formatDateTimeWithSlash(params.value) : '';
            },
        });
    }
    return columnDefs;
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function getRowClass(params) {
  return 'bg-gradient'
}

function getRowStyle(params) {
    return { background: params.data.TpTransacEirColor};
}

export default {
  name: 'dispatch-export-list',
  components: {
    ModalRegister
   },
  data,
  beforeMount,
  mixins: [GeneralMixin, AgGrid, YardManagement],
  methods: {
    getYardDispatchImportList,
    getYardVisit,
    onGridReady,
    onQuickFilterChanged,
    onPageSizeChanged,
    onBtnExport,
    validateDateRange,
    getRowClass,
    getRowStyle,
    getDateRange,
    refreshComponent,
  },
  computed: {
    RowData,
    VisitOptions,
    columnDefs,
    localeText,
    ...mapState({
        user: state => state.auth.user,
        dropItemReport: state => state.yardManagement.dropItemReport,
        yardData: state => state.yardManagement.yardData,
        CompanyBranchId: state => state.auth.branch.CompanyBranchId,
    })
  },
  watch: {
    dropItemReport: function (val) {
        if (val==2) {
            this.refreshComponent(true);
        }
    },
    Visit: function (NewValue) {
        if(NewValue?.VisitId){
            this.previousDate = DateFormater.formatDateRange(NewValue.Ata);
            this.searchFilterDate.fromDay = NewValue.CumputedAta;
        }
        else{
            this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
            this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(moment(this.laterDate).subtract(1, 'M'));
        }
    },
    showGrid: function (NewValue) {
        if (NewValue) {
            this.$store.state.yardManagement.loading = false
        } else {
            this.$store.state.yardManagement.loading = true;
        }
    }
  }
};
</script>